<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <Button
          label="Add New"
          icon="pi pi-plus"
          class="ml-2 p-button-success"
          @click="$router.push('sections/add')"
        />
        <!-- <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <TreeTable
      ref="dt"
      :value="list"
      class="p-datatable-customers"
      dataKey="key"
      :rowHover="true"
      :loading="loading"
    >
      <template #header>
        <div class="table-header">
          Cat Production Aufführen

          <div style="text-align: left"></div>
        </div>
      </template>
      <template #empty>Keine Daten</template>
      <template #loading>Daten werden geladen, bitte warten... </template>
      <Column field="id" header="#" :expander="true" />
      <Column field="id" header="ID" >
        <template #body="slotProps">
          {{  slotProps.node.id }}
        </template>
      </Column>
      <Column field="name.en" header="Name (EN)">
        <template #body="slotProps">
          {{ JSON.parse(slotProps.node.name).de }}
        </template>
      </Column>
      <Column field="name.de" header="Name (DE)">
        <template #body="slotProps">
          {{ JSON.parse(slotProps.node.name).de }}
        </template>
      </Column>

      <Column field="slug" header="Slug" >
        <template #body="slotProps">
          {{ slotProps.node.slug }}
        </template>
      </Column>
      <Column field="cattype" header="Type">
        <template #body="slotProps">
          {{ slotProps.node.cattype == 1 ? 'Sub' : 'Main' }}
        </template>
      </Column>

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
        
          <Button
            type="button"
            @click="$router.push('sections/edit/' + slotProps.node.id)"
            icon="pi pi-pencil"
            class="p-button-success p-mr-2 p-button-rounded"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            @click="deleteItem(slotProps.node.id)"
            class="p-button-warning ml-2 p-button-rounded"
          ></Button>
        </template>
      </Column>
    </TreeTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      loading: true,
      filters: {},
    };
  },
  methods: {
    getData() {
      this.$http.get(`sections/getTree`).then(
        (response) => {
          this.loading = false;
          this.list = response.data.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    deleteItem(id) {
      this.$confirm.require({
        message: "Are you sure you want to delete records?",
        header: "Yes Delete Record",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$http.delete(`sections/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: "error",
                summary: "Done Successfully",
                detail: "Deleted successfully",
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
        },
      });
    },
  },
  created() {
    this.getData();

    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>
